<template>
    <card>
        <body-card style="margin: 0;border: none;">
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGarden.task_assign') }} {{ $t('globalTrans.details') }}</h4>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-overlay :show="loading">
                        <b-form @submit.prevent="handleSubmit(saveAchievement)" @reset.prevent="reset" autocomplete="off">
                            <div class="p-3">
                                <b-row>
                                    <b-col sm="12">
                                        <b-table-simple small resonsive borderless>
                                            <b-tr>
                                                <b-th width="25%">{{ $t('teaGardenService.tea_garden_name') }}</b-th>
                                                <b-th width="2%">:</b-th>
                                                <b-td>
                                                    {{ getGardenName(formData.garden_id) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('teaGarden.task_name') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ getTaskName(formData.task_id) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('teaGardenConfig.description') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ currentLocale === 'en' ? formData.description_en : formData.description_bn }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('globalTrans.start_date') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ formData.start_date | dateFormat }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('globalTrans.end_date') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ formData.end_date | dateFormat }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('teaGarden.working_days') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ $n(formData.working_days) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr v-if="taskUnitId">
                                                <b-th>{{ $t('teaGarden.target') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ $n(formData.target) }} {{ getUnitName(taskUnitId) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr v-if="taskUnitId">
                                                <b-th>{{ $t('teaGarden.total_target') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ $n(formData.working_day_target) }} {{ getUnitName(taskUnitId) }}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-th>{{ $t('teaGarden.achievement_date') }}</b-th>
                                                <b-th>:</b-th>
                                                <b-td>
                                                    {{ formData.achievement.achievement_date }}
                                                </b-td>
                                            </b-tr>
                                        </b-table-simple>
                                        <body-card>
                                            <!-- search section start -->
                                            <template v-slot:headerTitle>
                                                <h4 class="card-title">{{ $t('teaGardenConfig.worker_information') + ' ' + $t('globalTrans.search') }}</h4>
                                            </template>
                                            <template v-slot:body>
                                                <b-row>
                                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <b-form-group label-for="worker_type">
                                                            <template v-slot:label>
                                                                {{ $t('teaGardenConfig.worker_type') }}
                                                            </template>
                                                            <b-form-select plain
                                                                           v-model="search.worker_type"
                                                                           :options="customWorkerTypeList"
                                                                           id="worker_type"
                                                            >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <ValidationProvider name="designation" vid="designation_id" rules="">
                                                            <b-form-group slot-scope="{ valid, errors }" label-for="designation_id">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGardenConfig.designation') }}
                                                                </template>
                                                                <b-form-select plain v-model="search.designation_id" :options="designationList" id="designation_id"
                                                                               :state="errors[0] ? false : (valid ? true : null)">
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <ValidationProvider name="Gender" vid="gender" rules="">
                                                            <b-form-group slot-scope="{ valid, errors }" label-for="gender">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGardenConfig.gender') }}
                                                                </template>
                                                                <b-form-select plain v-model="search.gender" :options="genderList" id="gender"
                                                                               :state="errors[0] ? false : (valid ? true : null)">
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <b-form-group label-for="mobile">
                                                            <template v-slot:label>
                                                                {{  $t('teaGardenConfig.worker_name') }}/{{ $t('teaGardenConfig.pf_id') }}
                                                            </template>
                                                            <b-form-input id="mobile"
                                                                          v-model="search.name_pf"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <b-button size="sm" variant="primary" class="mt-20" @click.prevent="getFormData">
                                                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </template>
                                            <!-- search section end -->
                                        </body-card>
                                        <body-card>
                                            <!-- search section start -->
                                            <template v-slot:headerTitle>
                                                <h4 class="card-title">{{ $t('teaGardenConfig.worker_list') }} {{ $t('globalTrans.list') }}</h4>
                                            </template>
                                            <template v-slot:headerAction>
                                                <b-button v-if="slipGiveMultiple.length > 0" type="button" pill variant="primary" @click="giveSlipMutipleWorkers">{{$t('teaGarden.give_slip')}}</b-button>
                                            </template>
                                            <template v-slot:body>
                                                <div style="overflow-x:auto;">
                                                    <b-table-simple bordered class="mt-3 mb-0">
                                                        <b-thead>
                                                            <b-tr>
                                                                <b-th width="5%" class="text-center" rowspan="2">
                                                                    <b-form-checkbox @change="selectAllSlipWorker" v-model="select_all" id="select_id"> {{ $t('globalTrans.checkAll') }}</b-form-checkbox>
                                                                </b-th>
                                                                <b-th width="5%" rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                                                                <b-th rowspan="2">{{ $t('teaGardenConfig.worker_name') }}</b-th>
                                                                <b-th rowspan="2">{{ $t('teaGardenConfig.pf_id') }}</b-th>
                                                                <b-th rowspan="2">{{ $t('globalTrans.designation') }}</b-th>
                                                                <b-th rowspan="2">{{ $t('teaGardenConfig.worker_type') }}</b-th>
                                                                <b-th colspan="4" class="text-center">{{ $t('teaGarden.achievement') }}</b-th>
                                                                <b-th width="7%" rowspan="2">{{ $t('teaGarden.difference') }}</b-th>
                                                                <b-th width="5%" rowspan="2">{{ $t('globalTrans.status') }}</b-th>
                                                                <b-th width="7%" rowspan="2">{{ $t('globalTrans.action') }}</b-th>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-th width="10%">{{ $t('teaGarden.morning') }}</b-th>
                                                                <b-th width="10%">{{ $t('teaGarden.afternoon') }}</b-th>
                                                                <b-th width="10%">{{ $t('teaGarden.extra') }}</b-th>
                                                                <b-th width="10%">{{ $t('globalTrans.total') }}</b-th>
                                                            </b-tr>
                                                        </b-thead>
                                                        <b-tbody>
                                                            <template v-if="formData.workers.length">
                                                                <b-tr v-for="(item, index) in formData.workers" :key="index">
                                                                    <b-td class="text-center">
                                                                        <b-form-checkbox v-model="slipGiveMultiple" v-if="formData.achievement.workers[index].status === 0" :value="formData.achievement.workers[index].id"></b-form-checkbox>
                                                                    </b-td>
                                                                    <b-td>{{ $n(index + 1) }}</b-td>
                                                                    <b-td>{{ currentLocale === 'en' ? item.worker.worker_name_en : item.worker.worker_name_bn }}</b-td>
                                                                    <b-td>{{ item.worker.pf_id | numberConvert }}</b-td>
                                                                    <b-td>{{ getDesignation(item.worker.designation_id) }}</b-td>
                                                                    <b-td>{{ getWorkerType(item.worker.worker_type) }}</b-td>
                                                                    <b-td>
                                                                        {{ $n(formData.achievement.workers[index].morning_achievement) }}
                                                                    </b-td>
                                                                    <b-td>
                                                                        {{ $n(formData.achievement.workers[index].afternoon_achievement)}}
                                                                    </b-td>
                                                                    <b-td>
                                                                        {{ $n(formData.achievement.workers[index].extra_achievement) }}
                                                                    </b-td>
                                                                    <b-td>{{ $n(formData.achievement.workers[index].achievement) }}</b-td>
                                                                    <b-td>{{ $n(formData.achievement.workers[index].diff) }}</b-td>
                                                                    <b-td>
                                                                        <span class="badge badge-success" v-if="formData.achievement.workers[index].status == 1">{{$t('teaGarden.slip_given') }}</span>
                                                                        <span class="badge badge-danger" v-else>{{$t('teaGarden.slip_not_given')}}</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <b-button class="moc-action-btn moc-assign-btn" :title="$t('teaGarden.give_slip')" @click="giveSlip(formData.achievement.workers[index])" v-if="!formData.achievement.workers[index].status">
                                                                            <i class="ri-newspaper-line"></i>
                                                                        </b-button>
                                                                        <b-button class="moc-action-btn moc-view-btn" v-b-modal.modal-achievement-slip @click="setItem(formData.achievement.workers[index])" :title="$t('globalTrans.view')" v-else>
                                                                            <i class="ri-eye-line"></i>
                                                                        </b-button>
                                                                    </b-td>
                                                                </b-tr>
                                                            </template>
                                                            <template v-else>
                                                                <b-tr>
                                                                    <b-td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                                </b-tr>
                                                            </template>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </div>
                                            </template>
                                        </body-card>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-form>
                    </b-overlay>
                </ValidationObserver>
            </template>
        </body-card>
        <b-modal id="modal-achievement-slip" size="lg" :title="$t('teaGarden.achievement_slip')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <template #modal-title class="flex-grow-1">
                {{ $t('teaGarden.achievement_slip') }}
                <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
                    {{  $t('globalTrans.export_pdf') }}
                </b-button>
            </template>
            <AchievementSlip  :item="item" :key="item.id" ref="achievementSlip"/>
        </b-modal>
    </card>
</template>
<script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    import { taskAssignIndividualAchievement, taskAssignGiveSlip, taskAssignGiveSlipMultiple } from '../../api/routes'
    import AchievementSlip from './AchievementSlip'
    export default {
        name: 'Details',
        components: { AchievementSlip },
        data () {
            return {
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                formData: {
                    id: 0,
                    total_achievement: '',
                    total_diff: '',
                    workers: [],
                    achievement: {
                        achievement_date: '',
                        workers: []
                    }
                },
                taskUnitId: 0,
                task: {
                    is_individual: 0
                },
                search: {
                    achievement_id: this.$route.query.achievement_id,
                    worker_type: 0,
                    designation_id: 0,
                    gender: 0,
                    mobile: '',
                    name_pf: ''
                },
                slipGiveMultiple: [],
                workerLoading: false,
                item: {
                    id: 0
                },
                select_all: false
            }
        },
        created () {
            this.getFormData()
        },
        computed: {
            id () {
                return this.$route.params.id
            },
            currentLocale () {
                return this.$i18n.locale
            },
            loading () {
                return this.$store.state.commonObj.loading
            },
            gdnTaskList () {
                return this.$store.state.TeaGardenService.commonObj.gdnTaskList
            },
            designationList: function () {
                return this.$store.state.TeaGardenService.commonObj.masterDesignationList
            },
            genderList: function () {
                return this.$store.state.TeaGardenService.commonObj.gender
            },
            unitList () {
                return this.$store.state.TeaGardenService.commonObj.unitList
            },
            customWorkerTypeList: function () {
                return this.$store.state.TeaGardenService.commonObj.workerType
            }
        },
        methods: {
            back () {
                this.$router.go(-1)
            },
            async getFormData () {
                // this.loading = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const result = await RestApi.getData(teaGardenServiceBaseUrl, taskAssignIndividualAchievement + '/' + this.id, this.search)
                if (result.success) {
                    this.formData = result.data
                    const obj = this.gdnTaskList.find(item => item.value === parseInt(this.formData.task_id))
                    this.task = obj
                    if (obj !== undefined && obj.unit_id) {
                        this.taskUnitId = obj.unit_id
                    }
                }
                // this.loading = false
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            },
            getGardenName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getTaskName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.gdnTaskList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getWorkerType (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.workerType.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getDesignation (id) {
                const obj = this.designationList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getGender (id) {
                const obj = this.genderList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getUnitName (id) {
                const obj = this.unitList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            setItem (item) {
                this.item = item
            },
            pdfExport () {
              this.$refs.achievementSlip.pdfExport()
            },
            giveSlip (item) {
                window.vm.$swal({
                    title: window.vm.$t('teaGarden.give_slip_confirm_msg'),
                    showCancelButton: true,
                    confirmButtonText: window.vm.$t('globalTrans.yes'),
                    cancelButtonText: window.vm.$t('globalTrans.no'),
                    focusConfirm: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                        RestApi.deleteData(teaGardenServiceBaseUrl, `${taskAssignGiveSlip}/${item.id}`).then(response => {
                            if (response.success) {
                                window.vm.$toast.success({
                                    title: window.vm.$t('globalTrans.success'),
                                    message: window.vm.$t('globalTrans.update_msg'),
                                    color: '#D6E09B'
                                })
                                this.getFormData()
                            } else {
                                window.vm.$toast.error({
                                    title: 'Error',
                                    message: 'Operation failed! Please, try again.'
                                })
                            }
                            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                        })
                    }
                })
            },
            giveSlipMutipleWorkers (item) {
                window.vm.$swal({
                    title: window.vm.$t('teaGarden.give_slip_confirm_msg'),
                    showCancelButton: true,
                    confirmButtonText: window.vm.$t('globalTrans.yes'),
                    cancelButtonText: window.vm.$t('globalTrans.no'),
                    focusConfirm: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                        RestApi.postData(teaGardenServiceBaseUrl, `${taskAssignGiveSlipMultiple}`, { achievement_workers_id: this.slipGiveMultiple }).then(response => {
                            if (response.success) {
                                window.vm.$toast.success({
                                    title: window.vm.$t('globalTrans.success'),
                                    message: window.vm.$t('globalTrans.update_msg'),
                                    color: '#D6E09B'
                                })
                                this.getFormData()
                                this.select_all = false
                                this.slipGiveMultiple = []
                            } else {
                                window.vm.$toast.error({
                                    title: 'Error',
                                    message: 'Operation failed! Please, try again.'
                                })
                            }
                            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                        })
                    }
                })
            },
            selectAllSlipWorker () {
                if (this.select_all) {
                    this.formData.achievement.workers.forEach(item => {
                        if (item.status === 0) {
                            this.slipGiveMultiple.push(item.id)
                        }
                    })
                } else {
                    this.slipGiveMultiple = []
                }
            }
        }
    }
</script>
<style scoped>
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }

    .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }

    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
        color: white;
    }

    .stepper-item.active {
        font-weight: bold;
    }

    .stepper-item.completed .step-counter {
        background-color: #4bb543;
        color:white;
    }

    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #4bb543;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 3;
    }

    .stepper-item:first-child::before {
        content: none;
    }
    .stepper-item:last-child::after {
        content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
    .tagTwo {
        position: relative;
        display: inline-block;
        border-radius: 6px;
        clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
        background: hsl(250deg, 100%, 40%);
        padding: 5px 25px;
        font-weight: 600;
        font-size: 12px;
        color: #FFF;
        transition: clip-path 500ms;
    }
    .tagTwo:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: hsl(252deg, 100%, 60%);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 6px 0;
        transition: transform 500ms;
    }
</style>
<style>
    .modal-title {
        flex-grow: 1 !important;
    }
</style>
