<template>
      <b-row>
        <b-col lg="12" sm="12">
          <b-overlay :show="loading">
            <body-card>
                <b-row>
                <b-col lg="12" sm="12">
                  <div style="font-size:12px; font-color:white; background-color: #dddddd; padding:6px">
                    <h5 class="card-title text-center" style="margin-bottom: 0;color: #214162;font-size: 1.40rem;"> {{ currentLocale === 'en' ? garden?.text_en : garden?.text_bn }}</h5>
                  </div>
                  <b-col md="12" class="mt-2">
                    <b-row>
                      <b-col lg="6">
                        <strong>{{ $t('globalTrans.division') }}</strong>: {{ garden.division_id ? getDivisionName(garden.division_id) : '' }}
                      </b-col>
                      <b-col lg="6" class="text-right">
                        <strong>{{ $t('teaGarden.achievement_date') }}</strong> : {{ detailsData.achievement_main.achievement_date | dateFormat }}
                      </b-col>
                    </b-row>
                  <table class="table table-borderless table-sm mt-5">
                    <tr>
                      <th width="28%"> {{ $t('teaGardenConfig.worker_name') }}</th>
                      <th width="2%">:</th>
                      <td width="30%">{{ ($i18n.locale === 'bn') ? detailsData.worker.worker_name_bn : detailsData.worker.worker_name_en }}</td>
                      <th width="25%"> {{ $t('teaGardenConfig.pf_id') }}</th>
                      <th width="2%">:</th>
                      <td width="13%">{{ detailsData.worker.pf_id | numberConvert }}</td>
                    </tr>
                    <tr>
                      <th> {{ $t('teaGarden.task_name') }}</th>
                      <th>:</th>
                      <td>{{ getTaskName(detailsData.achievement_main.task_assign.task_id) }}</td>
                      <th> {{ $t('globalTrans.start_date') }}</th>
                      <th>:</th>
                      <td>{{ detailsData.achievement_main.task_assign.start_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th> {{ $t('globalTrans.end_date') }}</th>
                      <th>:</th>
                      <td>{{ detailsData.achievement_main.task_assign.end_date | dateFormat }}</td>
                      <th>{{ $t('teaGarden.working_days') }}</th>
                      <th>:</th>
                      <td>
                        {{ $n(detailsData.achievement_main.task_assign.working_days) }}
                      </td>
                    </tr>
                    <tr>
                      <th> {{ $t('teaGarden.target') }}</th>
                      <th>:</th>
                      <td>{{ $n(detailsData.achievement_main.task_assign.target) }}</td>
                      <th> {{ $t('teaGarden.morning') + ' ' + $t('teaGarden.achievement') }}</th>
                      <th>:</th>
                      <td>{{ $n(detailsData.morning_achievement) }}</td>
                    </tr>
                    <tr>
                      <th> {{ $t('teaGarden.afternoon') + ' ' + $t('teaGarden.achievement') }}</th>
                      <th>:</th>
                      <td>{{ $n(detailsData.afternoon_achievement) }}</td>
                      <th> {{ $t('teaGarden.difference') }}</th>
                      <th>:</th>
                      <td>{{ $n(detailsData.diff) }}</td>
                    </tr>
                    <tr>
                      <th> {{ $t('globalTrans.total') + ' ' + $t('teaGarden.achievement') }}</th>
                      <th>:</th>
                      <td>{{ $n(detailsData.achievement) }}</td>
                    </tr>
                  </table>
                  </b-col>
                </b-col>
              </b-row>
            </body-card>
          </b-overlay>
        </b-col>
      </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { taskAssignAchievementSlip } from '../../api/routes'
export default {
  name: 'AchievementSlip',
  props: ['item'],
  data () {
    return {
      // loading: false,
      errors: [],
      detailsData: {
        achievement_main: {
          id: 0,
          task_assign: {
            id: 0
          }
        },
        worker: {
          id: 0,
          pf_id: ''
        }
      },
      baseUrl: teaGardenServiceBaseUrl,
      detailsItemId: '',
      garden: {
        id: 0,
        division_id: 0
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    masterTeaGardenGenInfoList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  created () {
    this.getDetailsData()
  },
  methods: {
    async getDetailsData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, taskAssignAchievementSlip + '/' + this.item.id)
      if (result.success) {
        this.detailsData = result.data
        this.getGarden(this.detailsData.achievement_main.task_assign.garden_id)
      } else {
        this.detailsData = {}
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getGarden (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
      this.garden = obj
    },
    getTaskName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.gdnTaskList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getDivisionName (id) {
        const tmpData = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === parseInt(id) && item.status === 1)
        if (this.$i18n.locale === 'bn') {
            return tmpData.text_bn
        } else {
            return tmpData.text_en
        }
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.id })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, taskAssignAchievementSlip + '/' + this.item.id, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
